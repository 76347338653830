import { render, staticRenderFns } from "./CoSignupForm.vue?vue&type=template&id=e854e53e"
import script from "./CoSignupForm.vue?vue&type=script&lang=js"
export * from "./CoSignupForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElInputWithFrame: require('/var/www/udimi-front-outside/releases/799/components/elements/ElInputWithFrame.vue').default,ElButtonWait: require('/var/www/udimi-front-outside/releases/799/components/elements/ElButtonWait.vue').default})
